// TODO: Need to make this environmental
import { BillingPlanDetailCode } from 'shared_server_client/types/billing_plan'
import { Post } from '../components/content-pool/edit-article/types'
import { Dataset } from 'generic/dataset'

declare const RASA_IMAGE_API_ENDPOINT: string

export interface HasHostedImageURL {
  hosted_image_url?: string,
}

export const sizedArticleUrl = (post: Post, height: number, width: number = null, crop = true): string => {
  return sizedUrl(post.hosted_image_url, height, width, crop)
}

export const sizedUrl = (
  url: string, height: number, width: number = null, crop = true , resize = true): string => {
  const u: string = url
  if (u && isHostedImage(u) ) {
    const params = []
    if (resize) {
      params.push(`h=${height}`)
      if (width) {
        params.push(`w=${width}`)
      }
      if (crop) {
        params.push('fit=crop')
        params.push('crop=entropy')
      }
    }
    let resultUrl = u
    if (params.length) {
      if (resultUrl.indexOf('?') > -1) {
        resultUrl += `&${params.join('&')}`
      } else {
        resultUrl += `?${params.join('&')}`
      }
    }
    return resultUrl
  } else {
    return u
  }
}

// I wonder why this only includes hosted-images-newsbrief?
const isHostedImage = (url: string): boolean => {
  return (url || '').includes('.s3.amazonaws.com/') || (url || '').includes(RASA_IMAGE_API_ENDPOINT)
}

// Easier to use than URLSearchParams
// https://stackoverflow.com/questions/8648892/convert-url-parameters-to-a-javascript-object
export const queryStringAsParams = (queryString: string): any => {
  try {
    const s = '{"' + decodeURI(queryString).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}'

    return JSON.parse(s)
  } catch (e) {
    return {}
  }
}

export const parseHref = (href: string) => {
  href = href || ''
  const parts = href ? href.split('?') : []
  const params = parts.length <= 1 ? {} :
    parts[1].split('#').length > 1 ? parts[1].split('#')[0] : queryStringAsParams(parts[1])
  const hash = href.split('#').length > 1 ? href.split('#')[1] : ''
  const route = parts[0].split('#')[0]
  return {
    hash,
    params,
    route,
  }
}

export const canAccessSegment = (avlFeatures, communityId): Promise<boolean> => {
  if (avlFeatures.indexOf(BillingPlanDetailCode.SEGMENT_CODE) === -1) {
    return Promise.resolve(false)
  } else {
    return new Dataset().loadCommunityDataset(
      'communityPartnerSegmentCode',
      communityId
    ).then((cp) => {
      return cp.length > 0 && cp[0].length > 0
    })
  }
}