import * as Redux from 'redux'
import * as Constants from './constants'

export const initializeApplication = <InitialState>(initialState: InitialState) => ({
  type: Constants.INITIALIZING_APPLICATION,
  ...initialState,
})

export type UrlParsedAction<Params = object> = Redux.Action & { params: Params }
export type UrlParsedActionCreator = (params: object) => UrlParsedAction
export const urlParsed: UrlParsedActionCreator = (params) =>
  ({ type: Constants.URL_PARSED, params })
export const searchParamsUpdated = (field: object) => ({ type: Constants.UPDATED, field })
export const updateUrl = (params: object, hash: string) => ({ type: Constants.UPDATE_URL, params, hash })
export const replaceParams = (params: object, hash: string) => ({ type: Constants.REPLACE_PARAMS, params, hash })
