import * as Constants from './constants'

const initialState = {authenticated: false}
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.VALID_TOKEN:
      return {
        ...state,
        authenticated: true,
      }
    case Constants.LOGGED_OUT:
      return {
        ...state,
        authenticated: false,
      }
    default:
      return state || initialState
  }
}
