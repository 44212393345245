import isNil from 'lodash/isNil'
import * as Redux from 'redux'
import * as Router from 'router'
import { TAB_SELECTED } from 'store/constants'
import { INITIALIZING_APPLICATION } from './constants'
import { parseHref, queryStringAsParams } from './utils'

export interface State {
  initialized: boolean,
  hash: string,
  params: any,
  route: string,
}
const initialState: State = {
  initialized: false,
  hash: '',
  params: {},
  route: '',
}

interface Location {
  hash: string,
  pathname: string,
  search: string,
}

interface InitializationAction extends Redux.Action {location: Location}
interface TabSelectionAction extends Redux.Action { hash: string }

export type Reducer = Redux.Reducer<State, Redux.Action & Router.PushAction & InitializationAction & TabSelectionAction>
export const reducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UNAUTHENTICATED':
      return {
        ...state,
        initialized: true,
      }
    case INITIALIZING_APPLICATION:
      return {
        ...state,
        initialized: true,
        hash: isNil(action.location.hash) ? '' : action.location.hash.split('#')[1],
        params: isNil(action.location.search) ? {} : queryStringAsParams(action.location.search.slice(1)),
        route: action.location.pathname,
      }
    case Router.PUSH:
      if (action.payload.href) {
        const {hash, params, route} = parseHref(action.payload.href)
        return {
          ...state,
          hash,
          params,
          route,
        }
      } else {
        return state
      }
    case TAB_SELECTED:
      return {
        ...state,
        hash: action.hash,
      }
    default:
      return state
  }
}
