import fromPairs from 'lodash/fromPairs'
import toPairs from 'lodash/toPairs'
import * as ReduxObservable from 'redux-observable'
import { map , mergeMap, tap} from 'rxjs/operators'
import {SAME_TAB, TAB_SELECTED } from 'store'
import * as Router from '../router'
import * as Actions from './actions'
import {APP_ENTER_STARTED, REPLACE_PARAMS, UPDATE_URL} from './constants'

export const onEnter: ReduxObservable.Epic = (action$, state$, {history}) =>
  action$.ofType(Router.ON_ENTER)
    .pipe(
      map(() => {
        return { type: APP_ENTER_STARTED }
      }),
    )

const onTabSelected: ReduxObservable.Epic = (action$) =>
  action$.ofType(TAB_SELECTED).pipe(
    map((action: any) => {
      if (window.location.hash !== `#${action.hash}`) {
        return Actions.updateUrl(null, action.hash)
      }
      return {type: SAME_TAB}
    }),
  )

const onUpdateUrl: ReduxObservable.Epic =
  (action$, store, { window }) =>
    action$.ofType(UPDATE_URL).pipe(
    map((action: any) => ({ action, params: new window.URLSearchParams(window.location.search) })),
    tap(({ action, params }) =>
      toPairs(action.params).map(([key, value]) =>
        params.has(key) ?
        params.set(key, value) :
        params.append(key, value))),
    // delete params with no value
    tap(({ action, params }) =>
      toPairs(action.params)
        .filter(([key, value]) => !value)
        .filter(([key, value]) => params.has(key))
        .map(([key, value]) => params.delete(key))),
    mergeMap(({ action, params }) => [
      Router.replace(`${window.location.pathname}${params ? `?${params}` : ''}${action.hash ? `#${action.hash}` : ''}`),
      Actions.urlParsed(
        fromPairs(Array.from(params.entries() as any[]))
      ),
    ]),
    )

const onReplaceParams: ReduxObservable.Epic =
  (action$, store, { window }) =>
    action$.ofType(REPLACE_PARAMS).pipe(
    map((action: any) => ({ action, params: new window.URLSearchParams()})),
    tap(({ action, params }) =>
      toPairs(action.params).map(([key, value]) =>
        params.append(key, value))),
    mergeMap(({ action, params }) => [
      Router.replace(`${window.location.pathname}${params ? `?${params}` : ''}${action.hash ? `#${action.hash}` : ''}`),
      Actions.urlParsed(
        fromPairs(Array.from(params.entries() as any[]))
      ),
    ]),
    )

export const epic = ReduxObservable.combineEpics(onEnter, onReplaceParams, onTabSelected, onUpdateUrl)
